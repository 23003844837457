import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  Badge,
} from "reactstrap";
import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";

const ListTaxObligations = () => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  const [businessProfile, setBusinessProfile] = useState();
  const [taxObligations, setTaxObligations] = useState([]);

  useEffect(() => {
    (async () => {
      if (principalLegalEntity) {
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/business-profile`,
          {
            headers: {
              Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
              "x-principal-legal-entity-uuid":
                principalLegalEntity.legal_entity_uuid,
            },
          }
        );

        const json = await response.json();
        setBusinessProfile(json);

        try {
          const response = await fetch(
            `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/tax`,
            {
              headers: {
                Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
                "x-principal-legal-entity-uuid":
                  principalLegalEntity?.legal_entity_uuid,
              },
            }
          );

          if (response.ok) {
            const json = await response.json();
            setTaxObligations(json.obligations);
          }
        } catch (e) {
          alert(`An unknown error occurred. (Server said: ${e})`);
        }
      }
    })();
  }, [setTaxObligations]);

  return (
    <>
      {/* Page content */}
      <Container className="mt--5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Tax Registrations</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {/*<th scope="col">Entity</th>*/}
                    <th scope="col">Country</th>
                    <th scope="col">Tax Authority</th>
                    <th scope="col">Tax Type</th>
                    <th scope="col">Tax ID</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/*<th scope="row">
                      {principalLegalEntity?.legal_entity_name}
                    </th>*/}
                    <td>Australia</td>
                    <td>ATO</td>
                    <td>Income Tax</td>
                    <td>
                      {businessProfile?.tax_registration?.tax_file_number}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
            <br />
            <br />
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Tax</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Name</th>
                    <th scope="col" className="text-right">
                      Taxable Amount
                    </th>
                    <th scope="col" className="text-right">
                      Tax Estimate
                    </th>
                    <th scope="col" />
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {taxObligations?.map((obligation, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">
                          {obligation.obligation_type ===
                          "business_activity_statement"
                            ? "BAS"
                            : "Tax"}
                        </th>
                        <td>{obligation.name}</td>
                        <td className="text-right">
                          {new Intl.NumberFormat("en-AU", {
                            style: "currency",
                            currency: "AUD",
                          }).format(
                            Math.abs(parseFloat(obligation.taxable_amount))
                          )}
                        </td>
                        <td className="text-right">
                          {new Intl.NumberFormat("en-AU", {
                            style: "currency",
                            currency: "AUD",
                          }).format(
                            Math.abs(parseFloat(obligation.tax_estimate))
                          )}
                        </td>
                        <td className="text-left">
                          {parseFloat(obligation.tax_estimate) < 0 ? (
                            <Badge
                              className="text-uppercase ml-3"
                              color="success"
                            >
                              Refundable
                            </Badge>
                          ) : undefined}
                        </td>
                        <td className="text-right">
                          <Button
                            color="success"
                            size="sm"
                            href={`/dashboard/tax/${
                              obligation.obligation_type ===
                              "business_activity_statement"
                                ? "au-bas"
                                : "au-itr"
                            }/${obligation.uuid}`}
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ListTaxObligations;
