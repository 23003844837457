import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import { FaRegCheckCircle } from "react-icons/fa";

import styled from "styled-components";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "../../../views/components/checkoutForm";

//const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, { stripeAccount: 'acct_1OjGMTR9pX4q0ayC' });

const BigTick = styled(FaRegCheckCircle)`
  font-size: 8rem;
  color: #28a745;
`;

const LiveInvoicePay = (props) => {
  const params = useParams();

  const [invoice, setInvoice] = useState(props.invoice);
  const [stripeClientSecret, setStripeClientSecret] = useState();
  const [showStripeBetaFeature, setShowStripeBetaFeature] = useState(false);

  useEffect(() => {
    (async () => {
      setShowStripeBetaFeature(
        invoice?.merchant.abn === "28631107714" ||
          invoice?.merchant.abn === "49672208472" ||
          invoice?.merchant.abn === "24903877928" ||
          invoice?.merchant.abn === "92368309959" ||
          invoice?.merchant.stripe_account
      );

      const fetchPaymentToken = async () => {
        const options = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        };
        const result = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/public/invoice/${params.uuid}/pay`,
          options
        );
        const resultJSON = await result.json();

        setStripeClientSecret(resultJSON.clientSecret);
      };

      await fetchPaymentToken();
    })();
  }, [params.uuid, setStripeClientSecret]);

  const options = {
    clientSecret: stripeClientSecret,
  };

  /*
   *  Formatting the Currency amounts using native javascript
   *  constructs...
   */
  const total = invoice?.line_items
    .reduce((previous, current) => {
      return (
        previous +
        current.quantity *
          current.unit_price *
          (parseFloat(current.tax_rate ?? 0) === 0
            ? 1
            : (parseFloat(current.tax_rate) + 1) / 10)
      );
    }, 0)
    .toFixed(2);

  const stripeTotal = Math.round(total * 100);

  /*
   *  Payment method selection:
   *
   */
  const invoiceIsPaid =
    invoice?.status === "PAID" || invoice?.status === "EXPENSE_PAID";

  const showBSBAccountNumber = invoice?.remittance.external_bsb;

  return (
    <>
      {invoiceIsPaid ? (
        <>
          <div className="pl-lg-4">
            <Row className="justify-content-md-center mt-5">
              <Col xs={12} sm={12}>
                <p className="text-center">
                  <BigTick />
                </p>
                <h3 className="text-center">
                  {invoice?.status === "EXPENSE_PAID"
                    ? "This Expense has been marked as Paid"
                    : "Invoice has been Paid, Thank You!"}
                </h3>
                <br />
                <br />
              </Col>
            </Row>
          </div>
        </>
      ) : undefined}
      {!invoiceIsPaid && showStripeBetaFeature && !showBSBAccountNumber ? (
        <>
          {stripeClientSecret ? (
            <div className="pl-lg-4 mt-3">
              <p>
                <strong>Payment:</strong>
              </p>
              <Row className="justify-content-md-center mt-1">
                <Col xs={12} sm={8}>
                  <Elements
                    stripe={
                      invoice?.merchant?.stripe_account
                        ? loadStripe(
                            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
                            { stripeAccount: invoice?.merchant?.stripe_account }
                          )
                        : loadStripe(
                            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
                          )
                    }
                    options={options}
                  >
                    <CheckoutForm
                      total={stripeTotal}
                      payButtonText={"Pay Now"}
                      returnURL={process.env.REACT_APP_STRIPE_REDIRECT_URL}
                    />
                  </Elements>
                </Col>
              </Row>
            </div>
          ) : undefined}
        </>
      ) : undefined}
      {!invoiceIsPaid && showBSBAccountNumber ? (
        <>
          <div className="pl-lg-4">
            <Row className="justify-content-md-center mt-5">
              <Col xs={12} sm={12}>
                <p>
                  <strong>Transfer Details:</strong>
                </p>
                {invoice?.remittance.swift_bank_code ? (
                  <p>SWIFT Code: {invoice?.remittance.swift_bank_code}</p>
                ) : undefined}
                {invoice?.merchant?.abn === "57521901759" ? ( // XXX: First Aid & Fire dont show byline
                  <p>Account Name: First Aid & Fire</p>
                ) : undefined}
                <p>BSB: {invoice?.remittance.external_bsb}</p>
                <p>Account: {invoice?.remittance.external_account_number}</p>
                {/*<p>{invoice?.external_bsb ? <><i style={{ fontSize: '12px' }}>If you are paying by direct bank transfer, {invoice?.merchant?.business_name} asks that you email payment confirmation to confirmations@accountedfor.com.au</i></> : ''}</p>*/}
                <br />
                <br />
              </Col>
            </Row>
          </div>
        </>
      ) : undefined}
    </>
  );
};

export default LiveInvoicePay;
